<template>
  <v-container>
    <!-- BOC:[breadcrumbs] -->
    <ABreadcrumb :items="breadcrumbs"></ABreadcrumb>
    <!-- EOC -->
    <SlideUpgrade />
    <SlideFooter />
    <SlideCopyright />
  </v-container>
</template>

<script>
import { mapState } from 'vuex'
import SlideCopyright from "@/components/views/PageMainHome/V3SlideCopyright.vue";
import SlideFooter from "@/components/views/PageMainHome/V6SlideFooter.vue";
import SlideUpgrade from "@/components/views/PageMainHome/V6SlideUpgradeR3.vue";

export default {
  components: {
    SlideCopyright,
    SlideFooter,
    SlideUpgrade,
  },
  computed: mapState({
    //
  }),
  props: [
    //
  ],
  data: () => ({
    //BOC:[breadcrumbs]
    breadcrumbs: [],
    //EOC
  }),
  created() {
    //BOC:[breadcrumbs]
    this.breadcrumbs.push({
      text: this.$t("route.PageMainHome"),
      to: { name: 'PageMainHome' },
      exact: true,
    })
    this.breadcrumbs.push({
      text: this.$t("route.PageMainUpgrade"),
      to: { name: 'PageMainUpgrade' },
      exact: true,
    })
    //EOC
  },
  mounted() {
    //
  },
  methods: {
    //
  }
}
</script>