<template>
  <v-container>
    <v-row justify="center" class="flex-md-row-reverse">
      <v-col sm="6" md="4">
        <v-card class="fill-height premium-plan">
          <v-img :src="plan.imagePath" lazy cover></v-img>
          <v-card-text class="text-center">
            <div class="mb-1 text-h4 black--text"><span class="mouse-memoirs">{{ $t('view.V6SlideUpgradeR2.planName') }}</span></div>
            <div class="mb-5 text-caption">{{ $t('view.V6SlideUpgradeR2.planValue') }}</div>
            <!-- <div class="mb-3" style="font-size: 20px;">{{ $t('view.V6SlideUpgradeR2.planDuration') }}</div> -->
            <div class="mb-5">
              <div class="red--text">{{ $t('view.V6SlideUpgradeR2.originalPrice') }}</div>
              <div class="text-h5 black--text">{{ $t('view.V6SlideUpgradeR2.discountedPrice') }}</div>
            </div>
            <!-- <div class="d-flex justify-center align-center mb-5">
              <v-chip color="warning" small class="mr-1">{{ $t('view.V6SlideUpgradeR2.discountChip') }}</v-chip>
            </div> -->
            <v-btn x-large class="success mx-auto animate-glow" rounded target="_blank" href="https://forms.gle/y9ub6gtLckuUP2rZ7">
              {{ $t('view.V6SlideUpgradeR2.upgradeButton') }}
            </v-btn>
            <!-- <div class="text-center mt-3">{{ $t('view.V6SlideUpgradeR2.giftMessage') }}</div> -->
          </v-card-text>
        </v-card>
      </v-col>
      <v-col sm="6" md="4">
        <v-card class="fill-height premium-plan">
          <v-card-text class="text-center">
            <div class="text-h6 mb-3">{{ $t('view.V6SlideUpgradeR2.header') }}</div>
            <div class="mb-3">
              <v-btn block large rounded outlined color="success" class="mb-1">{{ $t('view.V6SlideUpgradeR2.readingHabitTitle') }}</v-btn>
              <div class="text-caption">{{ $t('view.V6SlideUpgradeR2.readingHabitValue') }}</div>
              <v-list dense class="text-left">
                <v-list-item v-for="(feature, index) in featureReading" :key="index">
                  <v-list-item-icon>
                    <v-icon color="success">mdi-check-circle</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>{{ $t(`view.V6SlideUpgradeR2.readingFeature${index + 1}`) }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </div>
            <div>
              <v-btn block large rounded outlined color="success" class="mb-1">{{ $t('view.V6SlideUpgradeR2.mathSkillsTitle') }}</v-btn>
              <div class="text-caption">{{ $t('view.V6SlideUpgradeR2.mathSkillsValue') }}</div>
              <v-list dense class="text-left">
                <v-list-item v-for="(feature, index) in featureMaths" :key="index">
                  <v-list-item-icon>
                    <v-icon color="success">mdi-check-circle</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>{{ $t(`view.V6SlideUpgradeR2.mathFeature${index + 1}`) }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ImagePremium from "@/assets/PageMainHomeV6/BannerBrightFuture.png";

export default {
  data: () => ({
    plan: {
      imagePath: ImagePremium,
    },
    featureReading: [1, 2, 3], // Dummy array for looping purposes
    featureMaths: [1, 2],  // Dummy array for looping purposes
  }),
  created() {
    //
  },
  beforeDestroy() {
    //
  },
  methods: {
    //
  },
};
</script>
